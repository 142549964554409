import React from "react"
import { Link } from "gatsby"

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href={`#`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" class="site-head-left">
            {/* <ul className="nav" role="menu">
              <li className="nav-home nav-current" role="menuitem">
                <Link to={`/`}>Home</Link>
              </li>
              <li className="nav-about" role="menuitem">
                <Link to={`/about`}>About</Link>
              </li>
              <li className="nav-elements" role="menuitem">
                <Link to={`/elements`}>Elements</Link>
              </li>
            </ul> */}
          </nav>
          <div className="site-head-center">
            <div className="site-head-center-block">
              <div>
                <Link className="site-head-logo" to={`/`}>
                  {title}
                </Link>
              </div>
              <div>An Adventure Photojournal</div>
            </div>
          </div>
          <div className="site-head-right">
            <ul className="nav" role="menu">
              <li className="nav-about" role="menuitem">
                <Link to={`/about`}>About</Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>

      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link> |{" "}
        <a
          href="https://www.instagram.com/odpixels/"
          title="Instagram Icon"
          target="_blank"
        >
          <img
            src={require("../../content/assets/instagram-icon.jpg")}
            alt="Instagram Icon PNG"
            width="15vh"
            height="100%"
          />
        </a>
      </footer>
    </div>
  )
}

export default Layout
